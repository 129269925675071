import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigateTo } from '../../hooks/useNavigateTo'
import cases from '../../mock/cases'
import styles from './header.module.css'
import { QuestionsContext } from '../../App'

const Header = () => {
	const navigateTo = useNavigateTo()
	const servicesRef = useRef<HTMLDivElement>(null)
	const productsRef = useRef<HTMLDivElement>(null)
	const casesRef = useRef<HTMLDivElement>(null)
	const [isServicesModalVisible, setServicesModalVisible] = useState(false)
	const [isProductsModalVisible, setProductsModalVisible] = useState(false)
	const [isCasesModalVisible, setCasesModalVisible] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [menuProd, setMenuProd] = useState(false)
	const [menuServ, setMenuServ] = useState(false)
	const setQuestionsModalVisible = useContext(QuestionsContext)
	
	enum ModalState {
		Services,
		Products,
		Cases,
	}
	
	const modalStates = [
		{
			id: ModalState.Services,
			state: isServicesModalVisible,
			setState: setServicesModalVisible,
			ref: servicesRef,
		},
		{
			id: ModalState.Products,
			state: isProductsModalVisible,
			setState: setProductsModalVisible,
			ref: productsRef,
		},
		{
			id: ModalState.Cases,
			state: isCasesModalVisible,
			setState: setCasesModalVisible,
			ref: casesRef,
		},
	]
	
	const toggleModal = (modal: ModalState) => {
		let state = modalStates.find(state => state.id === modal)
		state?.setState(!state?.state)
		let states = modalStates.filter(state => state.id !== modal)
		for (let i = 0; i < states.length; i++) {
			if(states[i].state) {
				states[i].setState(false)
			}
		}
	}
	const handleClickOutside = (event: MouseEvent) => {
		for(let state of modalStates) {
			if (state.ref.current && !state.ref.current.contains(event.target as Node)) {
				state.setState(false)
			}
		}
		
		if (servicesRef.current &&
			!servicesRef.current.contains(event.target as Node) &&
			productsRef.current &&
			!productsRef.current.contains(event.target as Node)) {
			setServicesModalVisible(false)
			setProductsModalVisible(false)
		}
	}
	const handleToggleServMenu = () => {
		setMenuServ(!menuServ)
		if (menuServ) {
			setMenuServ(false)
		}
	}
	const handleToggleProdMenu = () => {
		setMenuProd(!menuProd)
		if (menuProd) {
			setMenuProd(false)
		}
	}
	const handleToggleMenu = () => {
		setIsMenuOpen(!isMenuOpen)
	}
	const scrollTo = (elementId: string): boolean => {
		const block = document.getElementById(elementId)
		if (block) {
			const elementPosition = block.getBoundingClientRect().top + window.pageYOffset
			const offsetPosition = elementPosition - 100
			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			})
			setIsMenuOpen(false)
			return true
		}
		return false
	}
	useEffect(() => {
		if (isMenuOpen) {
			document.body.classList.add('no-scroll')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}, [isMenuOpen])
	const handleQuestionsClick = () => {
		if (!scrollTo('questions')) {
			setQuestionsModalVisible(true)
			setIsMenuOpen(false)
		}
	}
	
	useEffect(() => {
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])
	return (
		<>
			<div className={styles.header}>
				<div className={styles.intoHeader}>
					<div className={styles.leftPart}>
						<div
							className={`${styles.logo} ${styles.mini}`} onClick={() => {
							navigateTo('/')
						}}>
							<b style={{ color: '#5CC3FD' }}>В</b><b>Р</b>
						</div>
						<div
							className={styles.link} onClick={() => {
							navigateTo('/about')
						}}>О КОМПАНИИ
						</div>
						<div
							className={styles.link}
							onClick={() => { toggleModal(ModalState.Services) }}
							id={styles['services']}
							ref={servicesRef}
						>
							УСЛУГИ <p className={styles.dots}>...</p>
							<div className={`${styles.modalContainer} ${isServicesModalVisible ? styles.active : ''}`}>
								<p onClick={() => navigateTo('/services/software')}>Разработка ПО</p>
								<p onClick={() => navigateTo('/services/web-services')}>Разработка веб-сервисов</p>
								<p onClick={() => navigateTo('/services/mobile-apps')}>Разработка мобильных приложений</p>
							</div>
						</div>
						<div
							className={styles.link}
							onClick={() => { toggleModal(ModalState.Products) }}
							id={styles['products']}
							ref={productsRef}
						>
							ПРОДУКТЫ <p className={styles.dots}>...</p>
							<div className={`${styles.modalContainer} ${isProductsModalVisible ? styles.active : ''}`}>
								{/* <p>Крипт.Клауд</p> */}								<p
								onClick={() => {navigateTo('/products/offergen')}}>Генератор сметы</p>
							</div>
						</div>
						<div
							className={styles.link}
							onClick={() => {toggleModal(ModalState.Cases)}}
							ref={casesRef}
						>
							КЕЙСЫ <p className={styles.dots}>...</p>
							<div className={`${styles.modalContainer} ${isCasesModalVisible ? styles.active : ''}`}>
								{cases.map((item, index) => (
									<p key={index} onClick={() => { navigateTo(item.link) }}>{item.shortName}</p>
								))}
							</div>
						</div>
						<div className={styles.link} onClick={() => scrollTo('Contacts')}>КОНТАКТЫ</div>
					</div>
					<div className={styles.openIcon} onClick={handleToggleMenu}></div>
				</div>
			</div>
			<div className={`${styles.openMenu} ${isMenuOpen ? styles.active : ''}`}>
				<div>
					<div>
						<div>
							<div className={styles.openMenuHeader}>
								<div
									className={styles.logo} onClick={() => {
									navigateTo('/')
									handleToggleMenu()
								}}
								>
									<b style={{ color: '#5CC3FD' }}>В</b><b>Р</b>
								</div>
								<div className={styles.iconAndButton}>
									<button className={`${styles.orderBtnMenu} blueButton`} onClick={handleQuestionsClick}>Оставить заявку
									</button>
									<div className={styles.closeIcon} onClick={handleToggleMenu}></div>
								</div>
							</div>
							<div className={styles.bodyMenu}>
								<div className={styles.menuLeft}>
									<div className={styles.item} onClick={handleToggleServMenu}>
										УСЛУГИ <img
										src='/Pictures/Arrow.svg'
										className={`${styles.arrow} ${menuServ ? styles.rotated : ''}`}
										id='services-arrow'></img>
									</div>
									{menuServ && (
										<div className={styles.servList}>
											<li
												onClick={() => {
													setIsMenuOpen(false)
													navigateTo('/services/software')
												}}>- Разработка ПО
											</li>
											<li
												onClick={() => {
													setIsMenuOpen(false)
													navigateTo('/services/web-services')
												}}>- Разработка веб-сервисов
											</li>
											<li
												onClick={() => {
													setIsMenuOpen(false)
													navigateTo('/services/mobile-apps')
												}}>- Разработка мобильных приложений
											</li>
										</div>
									)}
									<div className={styles.item} onClick={handleToggleProdMenu}>
										ПРОДУКТЫ <img
										src='/Pictures/Arrow.svg'
										className={`${styles.arrow} ${menuProd ? styles.rotated : ''}`}
										id='services-arrow'></img>
									</div>
									{menuProd && (
										<div className={styles.prodList}>
											{/* <li>- Крипт.Клауд</li> */}
											<li
												onClick={() => {
													setIsMenuOpen(false)
													navigateTo('/products/offergen')
												}}>- Генератор сметы
											</li>
										</div>
									)}
								</div>
								<div className={styles.menuRight}>
									<div
										className={styles.item} onClick={() => {
										navigateTo('/about')
										setIsMenuOpen(false)
									}}>О КОМПАНИИ
									</div>
									<div className={styles.item} onClick={() => scrollTo('Cases')}>КЕЙСЫ</div>
									<div className={styles.item} onClick={() => scrollTo('Contacts')}>КОНТАКТЫ</div>
									<div className={styles.phoneNumber}>+7 (995) 668-35-84</div>
									<div className={styles.socialLinks}>
										<img
											src='/Pictures/tg-icon-50.svg' onClick={() => {
											window.open('https://t.me/ooo_vebrazrabotka')
										}} /> <img
										src='/Pictures/vk-icon-50.svg' onClick={() => {
										window.open('https://vk.com/vr_it_ru')
									}} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Header